// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Tab_tab_3wWa6{position:fixed;bottom:.88rem;left:50%;transform:translateX(-50%);width:5.22667rem;height:1.33333rem;box-sizing:border-box;padding:0 1.2rem;display:flex;justify-content:space-between;align-items:center;background:#212121;box-shadow:0 .53333rem .8rem 0 rgba(0,0,0,.4);border-radius:.96rem;z-index:9}.Tab_tab_3wWa6 .Tab_home_32YW6{width:.61333rem;height:.64rem}.Tab_tab_3wWa6 .Tab_personal_T362U{width:.50667rem;height:.64rem}", ""]);
// Exports
exports.locals = {
	"tab": "Tab_tab_3wWa6",
	"home": "Tab_home_32YW6",
	"personal": "Tab_personal_T362U"
};
module.exports = exports;
