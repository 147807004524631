<template>
  <div :class="$style.wrap">
    <!-- 头部logo -->
    <div :class="$style.head">
      <img :class="$style.logo" src="@/assets/img/logo.png" alt="logo" />
    </div>
    <!-- banner -->
    <van-swipe :autoplay="3000" :show-indicators="false">
      <van-swipe-item
        v-for="banner in bannerList"
        :key="banner.id"
        @click="handle2BannerDetail(banner)"
      >
        <img :class="$style.banner" :src="banner.image_url" alt="banner" />
      </van-swipe-item>
      <van-swipe-item v-if="!bannerList.length">
        <div :class="$style.bannerLoading">
          <van-loading type="spinner" />
        </div>
      </van-swipe-item>
    </van-swipe>
    <!-- tab -->
    <div :class="$style.tabWrap">
      <van-tabs
        v-model="active"
        background="#121212"
        :class="$style.tab"
        color="#fff"
        title-inactive-color="#666666"
        title-active-color="#fff"
      >
        <van-tab title="推荐艺术家">
          <div :class="$style.tabContent">
            <ArtistCard
              v-for="item in artistList"
              :info="item"
              :key="item.id"
              :class="$style.card"
            />
          </div>
        </van-tab>
        <van-tab title="国家纺织信息中心">
          <div :class="$style.tabContent">
            <img
              @click="handle2SpinDetail(item)"
              v-for="item in ddcList"
              :key="item.id"
              :class="$style.works"
              :src="item.image_url"
            />
          </div>
        </van-tab>
      </van-tabs>
      <span :class="$style.btn" @click="handle2ListPage">查看全部</span>
    </div>
    <!-- 优选推荐 -->
    <div :class="$style.recommendList">
      <div :class="$style.listTitle">
        <span>优选推荐</span>
        <router-link to="/optimization" :class="$style.all"
          >查看全部</router-link
        >
      </div>
      <div
        v-for="item in recommendList"
        :key="item.id"
        :class="$style.listItem"
        @click="handle2ClothDetail(item.id)"
      >
        <img :class="$style.listImg" :src="item.image_url" />
        <p :class="$style.name">
          {{ item.name }}
        </p>
        <div :class="$style.listBottom">
          <div :class="$style.bottomLeft">
            <img :class="$style.avatar" :src="item.headimg" />
            <span>{{ item.artist_name }}</span>
          </div>
          <span v-if="Number(item.price)" :class="$style.price"
            >¥{{ item.price }}</span
          >
        </div>
      </div>
      <div :class="$style.finished" v-if="recommendList.length">到底了</div>
    </div>
    <!-- 底部tab -->
    <Tab active="home" />
  </div>
</template>

<script>
import ArtistCard from "@/components/ArtistCard";
import Tab from "@/components/Tab";
import {
  fetchBannerList,
  fetchArtistList,
  fetchDdcList,
  fetchRecommendList,
} from "@/api";
export default {
  components: {
    Tab,
    ArtistCard,
  },
  data() {
    return {
      active: 0, // 0是艺术家，1是国家纺织中心
      bannerList: [],
      artistList: [],
      ddcList: [],
      recommendList: [],
    };
  },
  computed: {},
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getBannerList();
      this.getArtistList();
      this.getDdcList();
      this.getRecommendList();
    },
    getBannerList() {
      fetchBannerList().then((res) => {
        const { list } = res;
        this.bannerList = list;
      });
    },
    getArtistList() {
      // 是否推荐: 1-推荐,0-不推荐
      fetchArtistList({ is_recommend: 1 }).then((res) => {
        const { list } = res;
        this.artistList = list;
      });
    },
    getDdcList() {
      fetchDdcList({ is_recommend: 1 }).then((res) => {
        const { list } = res;
        this.ddcList = list;
      });
    },
    getRecommendList() {
      fetchRecommendList().then((res) => {
        const { list } = res;
        this.recommendList = list;
      });
    },
    handle2ListPage() {
      // 0是艺术家，1是国家纺织中心
      const url = this.active === 0 ? "/artist" : "/spin";
      this.$router.push(url);
    },
    handle2SpinDetail(item) {
      const { id, is_paid } = item;
      if (Number(is_paid)) {
        // 被当前用户购买
        this.$router.push({
          path: "/personal/collect",
          query: {
            department_id: 2,
            ddc_id: id,
          },
        });
      } else {
        this.$router.push({
          path: `/spin/detail/${id}`,
        });
      }
    },
    handle2ClothDetail(id) {
      this.$router.push({ path: `/cloth/detail/${id}` });
    },
    handle2BannerDetail(banner) {
      // banner跳转类型:1-推荐列表,2-艺术家列表,3-国家纺织中心作品列表,4-艺术家详情,5-艺术家作品详情，6-国家纺织作品详情
      const { jump_id, jump_type } = banner;
      const linkMap = {
        1: "/optimization",
        2: "/artist",
        3: "/spin",
        4: `/artist/detail/${jump_id}`,
        5: `/cloth/detail/${jump_id}`,
        6: `/spin/detail/${jump_id}`,
      };

      this.$router.push({ path: linkMap[jump_type] });
    },
  },
};
</script>

<style lang="scss" module>
.wrap {
  :global {
    .van-tab {
      justify-content: flex-start;
      flex: 0 0 auto;
      padding: 0;
      margin-right: 23px;
      font-weight: 500;
    }
  }
  :global {
    .van-tab__text {
      font-size: 16px;
      font-weight: 500;
    }
  }
  min-height: 100vh;
  box-sizing: border-box;
  padding-top: 20px;
  padding-bottom: 20px;
  .head {
    height: 44px;
    padding: 0 32px;
    display: flex;
    align-items: center;
    .logo {
      width: 82px;
    }
  }
  .bannerLoading {
    width: 311px;
    height: 160px;
    margin: 24px auto 20px;
    border-radius: 31px;
    background-color: #f7f8fa;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .banner {
    display: block;
    width: 311px;
    height: 160px;
    margin: 24px auto 20px;
    border-radius: 31px;
  }
  .tabWrap {
    position: relative;
    .tab {
      margin-left: 32px;
      /* 滚动条透明 */
      ::-webkit-scrollbar {
        height: 0;
        width: 0;
        color: transparent;
      }
      .tabContent {
        padding-top: 12px;
        overflow-x: auto;
        display: flex;
        .card {
          flex-shrink: 0;
          margin-right: 8px;
        }
        .works {
          margin-top: 6px;
          margin-right: 11px;
          width: 89px;
          height: 89px;
          border-radius: 13px;
          flex-shrink: 0;
        }
      }
    }
    .btn {
      position: absolute;
      top: 14px;
      right: 32px;
      font-size: 14px;
      color: #999;
      font-weight: 500;
    }
    /* :global {
      .van-tab {
        justify-content: flex-start;
        flex: 0 0 auto;
        padding: 0;
        margin-right: 23px;
        font-weight: 500;
      }
      .van-tab__text {
        font-size: 16px;
        font-weight: 500;
      }
    } */
  }
  .recommendList {
    margin-top: 32px;
    padding: 0 32px;
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    .listTitle {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
      .all {
        font-size: 14px;
        color: #999;
      }
    }
    .listItem {
      border-radius: 31px;
      margin-bottom: 16px;
      display: flex;
      flex-direction: column;
      background: rgba(216, 216, 216, 0.1);
      box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.4);
      .listImg {
        width: 311px;
        height: 311px;
        border-radius: 31px;
      }
      .name {
        line-height: 24px;
        padding: 16px 16px 8px 16px;
      }
      .listBottom {
        background: #212121;
        height: 48px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 16px;
        border-radius: 0 0 31px 31px;
        .bottomLeft {
          display: flex;
          align-items: center;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          color: #8e8e95;
          .avatar {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            margin-right: 8px;
          }
        }
        .price {
          font-size: 16px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
        }
      }
    }
    .finished {
      margin: 36px auto 116px;
      font-size: 12px;
      text-align: center;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #8e8e95;
    }
  }
}
</style>
