<template>
  <div :class="$style.tab">
    <img
      :class="$style.home"
      @click="handle2Page('home')"
      :src="
        active === 'home'
          ? require('@/assets/img/icon-home-active.png')
          : require('@/assets/img/icon-home.png')
      "
    />
    <van-badge :dot="dot"
      ><img
        :class="$style.personal"
        @click="handle2Page('personal')"
        :src="
          active === 'personal'
            ? require('@/assets/img/icon-personal-active.png')
            : require('@/assets/img/icon-personal.png')
        "
      />
    </van-badge>
  </div>
</template>

<script>
export default {
  props: {
    active: {
      type: String,
      default: "home", // home 为主页，personal为个人中心
    },
    dot: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handle2Page(type) {
      if (type === "home") {
        this.$router.push("/");
      }
      if (type === "personal") {
        this.$router.push("/personal");
      }
    },
  },
};
</script>

<style lang="scss" module>
.tab {
  position: fixed;
  bottom: 33px;
  left: 50%;
  transform: translateX(-50%);
  width: 196px;
  height: 50px;
  box-sizing: border-box;
  padding: 0 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #212121;
  box-shadow: 0px 20px 30px 0px rgba(0, 0, 0, 0.4);
  border-radius: 36px;
  z-index: 9;
  .home {
    width: 23px;
    height: 24px;
  }
  .personal {
    width: 19px;
    height: 24px;
  }
}
</style>
